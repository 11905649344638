import React from 'react'
import { formatMoney } from 'accounting'
import {
  ContainerTitleTransaction,
  TitleResponseTransaction,
  TitleResponseInfo,
  TransactionInfo,
} from './styles'

const TransactionResult = ({ data }: any) => {
  return (
    <ContainerTitleTransaction color={data.color}>
      <TransactionInfo>
        <TitleResponseInfo>
          Ha realizado una transacción a favor de <strong>{data.userBenefited}</strong>
        </TitleResponseInfo>
        <TitleResponseTransaction color={data.color}>
          TRANSACCIÓN {data.status.toUpperCase()}
        </TitleResponseTransaction>
        <TitleResponseTransaction color={data.color}>
          {`${formatMoney(data.amount)} ${data.currency_code}`}
        </TitleResponseTransaction>
      </TransactionInfo>
    </ContainerTitleTransaction>
  )
}

export default TransactionResult
