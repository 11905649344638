import styled from 'styled-components'

export const Select = styled.select`
  width: 100%;
  height: 2.5rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey60};
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.colors.grey30};
  border-radius: 4px;
  padding-left: 0.5rem;
  option {
    background: ${(props) => props.theme.colors.white};
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`
