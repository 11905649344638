import styled from 'styled-components'
import { Form } from '../../theme/common'

export const SubmitUserFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 2.5rem;
  }
`

export const SubmitUserFormHeader = styled.div`
  h4 {
    font-weight: 400;
    color: ${(props) => props.theme.colors.grey60};
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.openSans};
    line-height: 22px;
    text-align: center;
  }

  p {
    font-size: 15px;
    font-family: ${(props) => props.theme.fonts.openSans};
    text-align: center;
    line-height: 19px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const CaptchaContainer = styled.div`
  overflow: hidden;
  display: inline-table;
  margin-top: 0.5rem;
  * {
    margin: 0 auto;
  }

  iframe {
    @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
      transform: scaleX(0.9) scaleY(0.79) translateY(-10px);
    }

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      transform: scaleX(0.9) scaleY(0.79) translateY(-10px);
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      transform: scaleX(0.9) scaleY(0.79) translateY(-10px);
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      transform: scaleX(0.9) scaleY(0.79) translateY(-10px);
    }

    @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
      transform: scaleX(0.79) scaleY(0.7) translateY(-1px);
    }
  }
`
