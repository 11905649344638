import styled from 'styled-components'
import { Button } from '../../theme/common'

export const NotFoundPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    max-width: 20vw;
  }
`

export const NotFoundTitle = styled.div`
  color: ${(props) => props.theme.colors.darkBlue110};
  font-family: ${(props) => props.theme.fonts.kanit};
  font-size: 32px;
  line-height: 40px;
`

export const InfoError = styled.p`
  color: ${(props) => props.theme.colors.darkBlue110};
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: 16px;
  line-height: 23px;
  text-align: center;
`
