import React, { useState } from 'react'
import { Button } from '../../../theme/common'
import { UserDataContainer, UserInfoText, UserName } from './styles'
import MaskedInput from '../../MaskedInput'
import { RootState } from '../../../redux/reducers'
import { UserReducerType, ViewReducerType } from '../../../types'
import { useSelector } from 'react-redux'
import { config } from '../../../config/enviroment'
import { EpaycoCheckout } from '../../../utils/epaycoCheckout'

const msj = {
  minValue: 'El valor ingresado no alcanza el monto mínimo permitido para el cliente',
  maxValue: 'El valor ingresado supera el monto máximo permitido para el cliente',
}

const UserData = () => {
  const userState: UserReducerType = useSelector((state: RootState) => state.user)
  const viewState: ViewReducerType = useSelector((state: RootState) => state.view)
  const { maxValue, minValue, email, name, phone, subdomain, publicKey, businessName } = userState
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [amount, setAmount] = useState(0)

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountValue = event.target.value.replace(/[,$.]/g, '')
    setAmount(Number(amountValue))
    setDisableButton(Number(amountValue) > 0 ? false : true)
    setErrorMessage('')
  }

  const handleOpen = () => {
    setLoadingButton(true)
    if (amount >= Number(minValue) && amount <= Number(maxValue)) {
      EpaycoCheckout(
        email,
        name,
        amount,
        viewState.view === 'embedded' ? '/embed/transaction' : '/transaction',
        viewState.view === 'embedded',
        publicKey,
        () => {
          setLoadingButton(false)
        }
      )
    } else {
      amount < Number(minValue)
        ? setErrorMessage(msj.minValue)
        : amount > Number(maxValue) && setErrorMessage(msj.maxValue)
      setLoadingButton(false)
    }
  }

  return (
    <UserDataContainer>
      <UserName>{businessName || name}</UserName>
      <UserInfoText>
        <b>{subdomain.substr(8)}</b>
      </UserInfoText>
      <UserInfoText>{email}</UserInfoText>
      <UserInfoText>{phone}</UserInfoText>
      <MaskedInput onChange={onInputChange} disabled={loadingButton} />
      {errorMessage !== '' && <span>{errorMessage}</span>}
      <Button
        disabled={disableButton}
        loading={loadingButton}
        type={'button'}
        onClick={() => handleOpen()}
      >
        Continuar
      </Button>
    </UserDataContainer>
  )
}

export default UserData
