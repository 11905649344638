import React, { useContext, useEffect, useState } from 'react'
import TransactionResult from './TransactionResult'
import TransactionData from './TransactionData'
import { TransactionContainer } from './styles'
import { SkeletonContainer } from '../../theme/common'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { ThemeContext } from 'styled-components'
import { config } from '../../config/enviroment'
import { useQuery } from '../../hooks/'

const TransactionInfo = () => {
  const { colors } = useContext(ThemeContext)
  const history = useHistory()
  const query = useQuery()

  const [transationData, setTransationData] = useState({
    loading: true,
    userBenefited: '',
    status: '',
    amount: '',
    currency_code: '',
    ref_payco: '',
    transaction_date: '',
    franchise: '',
    transaction_id: '',
    transaction_state: '',
    id_invoice: '',
    approval_code: '',
    color: '',
  })

  const handleTransaction = () => {
    axios
      .get(`${config.urlResultCheckout}${query.get('ref_payco')}`)
      .then((res) => {
        const { data } = res
        if (data.success) {
          const transation = data.data
          setTransationData({
            loading: false,
            userBenefited: transation.x_business,
            status: transation.x_transaction_state.toUpperCase(),
            amount: transation.x_amount,
            currency_code: transation.x_currency_code,
            ref_payco: transation.x_ref_payco,
            transaction_date: transation.x_transaction_date,
            franchise: transation.x_franchise,
            transaction_id: transation.x_transaction_id,
            transaction_state: transation.x_transaction_state,
            id_invoice: transation.x_id_invoice,
            approval_code: transation.x_approval_code,
            color: handleColor(transation.x_transaction_state),
          })
        } else {
          history.push('/')
        }
      })
      .catch((e) => {
        history.push('/')
      })
  }

  const handleColor = (status: string) => {
    let result = colors.success

    if (status === 'Rechazada' || status === 'Fallida') {
      result = colors.rejected
    } else if (status === 'Pendiente') {
      result = colors.pending
    }

    return result
  }

  useEffect(() => {
    handleTransaction()
  }, [])

  return (
    <TransactionContainer>
      {transationData.loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <TransactionResult data={transationData} />
          <TransactionData data={transationData} />
        </>
      )}
    </TransactionContainer>
  )
}

const LoadingSkeleton = () => {
  return (
    <SkeletonContainer>
      <Skeleton height={'8rem'} width={'100%'} />
      <Skeleton count={5} width={'100%'} />
      <Skeleton height={'2.5rem'} width={'100%'} />
      <Skeleton height={'2.5rem'} width={'100%'} />
    </SkeletonContainer>
  )
}

export default TransactionInfo
