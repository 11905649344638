import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 1.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  > * {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      margin-top: 0.5rem;
    }
  }
`
export const SkeletonContainer = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>
}
