import styled from 'styled-components'
import { THeaderContainer } from '../../types/HeaderProps'

export const MyEpacycoInfoContainer = styled.div<THeaderContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  p {
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.segeoUI};
    text-align: left;
    line-height: 29px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    height: 27rem;
    background-color: ${(props) => props.theme.colors.darkBlue100};
    padding: 1rem;
    background-image: url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;

    ::before {
      height: 27rem;
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    > * {
      z-index: 1;
    }
  }
`

export const MyEpaycoTitle = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 4rem;
    max-width: 100%;
    svg {
      max-width: inherit;
    }
  }
`

export const LogoEpaycoMe = styled.img`
  width: 29rem;
  margin-left: -44px;
  margin-bottom: -68px;
`
