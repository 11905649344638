import styled from 'styled-components'
import { InputProps } from '../../types'

export const Input = styled.input<InputProps>`
  color: ${(props) => props.theme.colors.grey3};
  background-color: ${(props) => (props.disabled ? props.theme.colors.grey15 : 'transparent')};
  border: solid ${(props) => (props.disabled ? '0px' : '1px')}
    ${(props) => props.theme.colors.grey30};
  border-radius: 4px;
  height: 2rem;
  width: 100%;
  outline: none;
  outline-style: none;
  height: 2.5rem;
  padding-left: 0.5rem;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.segeoUI};
  ::placeholder {
    color: ${(props) => props.theme.colors.grey60};
  }

  :focus {
    border-color: ${(props) => props.theme.colors.grey3};
  }
`
