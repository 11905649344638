import { ViewReducerType } from '../../types'
import { SET_VIEW, SET_LOADING } from '../actions/index'

const initialState: ViewReducerType = {
  view: 'home',
  loading: false,
}

const viewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_VIEW: {
      return {
        ...state,
        loading: true,
        view: action.payload,
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default viewReducer
