import { domain } from 'node:process'
import React, { useEffect, useState } from 'react'
import { config } from '../../config/enviroment'
import { Input } from '../../theme/common'
import { SearchUserInputProps } from '../../types/'
import { countries } from '../../utils/countries'
import Selector from '../Selector'
import {
  InputFormContainer,
  SearchUserInputContainer,
  SearchUserUrlContainer,
  SearchUserPhoneContainer,
  FlagContainer,
  FlagLabel,
  Flag,
  PhonePrefixContainer,
} from './styles'

const SearchUserInput = ({
  type,
  url,
  disabled = false,
  onChange,
  errorMessage = '',
}: SearchUserInputProps) => {
  const [error, setError] = useState('')
  const [phonePrefixSelected, setPhonePrefixSelected] = useState('+57')

  useEffect(() => {
    setError('')
  }, [type])

  const validate = (value: string): string => {
    switch (type) {
      case 'phone':
        return !/^[0-9]*$/.test(value) ? 'El teléfono no es válido' : ''
      case 'email':
        return !/^[^@]+@[a-zA-Z0-9._-]+\.+[a-zA-Z._-]{2,4}$/.test(value)
          ? 'El correo electrónico no es válido'
          : ''
      case 'domain':
        return !/^\S*$/.test(value) ? 'El usuario no es válido' : ''
      default:
        return ''
    }
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validation = validate(event.target.value)
    const value = ((): string => {
      switch (event.target.name) {
        case 'domain':
          return `https://${event.target.value}.${url?.substr(8)}`
        case 'phone':
          return `${phonePrefixSelected}-${event.target.value}`
        default:
          return event.target.value
      }
    })()
    onChange(value, validation === '' && event.target.value !== '')
    setError(validation)
  }

  const onPhonePrefixSelected = (value: string | undefined) => {
    if (value) setPhonePrefixSelected(value)
  }

  switch (type) {
    case 'phone':
      return (
        <SearchUserPhoneContainer>
          <PhonePrefixContainer>
            <Selector
              defaultValue={countries.find((country) => country.name === 'Colombia')}
              items={countries}
              disabled={disabled}
              renderOption={(item) => {
                return (
                  <FlagContainer>
                    <Flag
                      src={
                        config.amazonUrl + '/my-epayco/flags/' + item.label.toLowerCase() + '.svg'
                      }
                    />
                    <FlagLabel>{item.value}</FlagLabel>
                  </FlagContainer>
                )
              }}
              onSelect={onPhonePrefixSelected}
            />
          </PhonePrefixContainer>
          <InputFormContainer>
            <Input type={type} name="phone" disabled={disabled} onChange={handleChangeInput} />
            <span>{error || errorMessage}</span>
          </InputFormContainer>
        </SearchUserPhoneContainer>
      )
    case 'domain':
      return (
        <InputFormContainer style={{ maxWidth: '100%' }}>
          <SearchUserUrlContainer>
            <div id="http">{'https:\\\\'}</div>
            <Input type="text" name="domain" disabled={disabled} onChange={handleChangeInput} />
            <div id="domain">{`.${url?.substr(8)}`}</div>
          </SearchUserUrlContainer>
          <span>{error || errorMessage}</span>
        </InputFormContainer>
      )

    case 'email':
      return (
        <InputFormContainer style={{ maxWidth: '100%' }}>
          <SearchUserInputContainer>
            <Input
              type={type}
              name="email"
              disabled={disabled}
              onChange={handleChangeInput}
              placeholder="correo@ejemplo.com"
            />
          </SearchUserInputContainer>
          <span>{error || errorMessage}</span>
        </InputFormContainer>
      )
    default:
      return (
        <SearchUserInputContainer>
          <Input type={type} disabled={disabled} placeholder="Seleccione" />
        </SearchUserInputContainer>
      )
  }
}

export default SearchUserInput
