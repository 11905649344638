import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

const Page = (props: Props) => {
  const { title } = props

  return (
    <Helmet>
      <title>{title ? title : 'Cargando ...'}</title>

      <script src={process.env.REACT_APP_URL_EPAYCO_CHECKOUT}></script>
    </Helmet>
  )
}

export default Page
