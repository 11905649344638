import React from 'react'
import { useHeaderView } from '../../hooks'
import Page from '../../theme/page'
import InfoCard from '../InfoCard'

const Embeddeded = () => {
  const { viewState, userState } = useHeaderView()
  return (
    <>
      <Page title={'Recibir pagos o realizar cobros con ePayco.me | ePayco.me'} />
      <InfoCard view={viewState} userInfo={userState} />
    </>
  )
}

export default Embeddeded
