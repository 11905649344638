import styled from 'styled-components'
import { Input } from '../../theme/common'

export const MaskedInputContainer = styled.div`
  width: 100%;
  margin: 1rem auto;
  ${Input} {
    text-align: center;
  }
`
