import React from 'react'
import { NotFoundSvg } from '../../assets/svg'
import { Button } from '../../theme/common'
import { InfoError, NotFoundPageContainer, NotFoundTitle } from './styles'
import { useHistory } from 'react-router'

const NotFound = () => {
  const history = useHistory()

  return (
    <NotFoundPageContainer>
      <NotFoundSvg />
      <NotFoundTitle>{`Página no encontrada`}</NotFoundTitle>
      <InfoError>{`La página a la que intentas acceder no existe`}</InfoError>
      <Button onClick={() => history.push('/')}>{`Ir al inicio`}</Button>
    </NotFoundPageContainer>
  )
}

export default NotFound
