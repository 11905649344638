import React, { useEffect, useState } from 'react'
import { SubmitUserFormContainer, SubmitUserFormHeader, InputContainer } from './styles'
import { PoweredByEpaycoLogo } from '../../assets/svg'
import { Button } from '../../theme/common/'
import SearchUserInput from '../SearchUserInput/'
import Selector from '../Selector'
import { SearchUserInputType } from '../../types'
import { config } from '../../config/enviroment'
import { DataService } from '../../services/dataService'
import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '../../redux/actions'
import Recaptcha from 're-capt-v3'
import { RootState } from '../../redux/reducers/index'
import { setToken } from '../../redux/actions/catchAction'

const SubmitUserForm = () => {
  const dataService = new DataService()
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false)
  const [inputType, setInputType] = useState<SearchUserInputType>('number')
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [inputDisabled, setInputDisabled] = useState(true)
  const [formValid, setFormValid] = useState(false)
  const tokenCaptcha: any = useSelector((state: RootState) => state.captcha)
  const captcha = new Recaptcha(config.captchaKey, 'login')

  const [selectData] = useState([
    {
      label: 'Seleccione',
      value: 'Seleccione',
    },
    {
      label: 'Correo electrónico',
      value: 'Correo electrónico',
    },
    {
      label: 'Teléfono',
      value: 'Teléfono',
    },
    {
      label: 'Usuario ePayco.me',
      value: 'Usuario ePayco.me',
    },
  ])

  useEffect(() => {
    setErrorMessage('')
  }, [inputType])

  const handleOnSelect = (selectedData: string | undefined) => {
    setInputDisabled(false)
    setFormValid(false)
    switch (selectedData) {
      case 'Seleccione':
        setInputDisabled(true)
        setInputType('number')
        break
      case 'Correo electrónico':
        setInputType('email')
        break
      case 'Teléfono':
        setInputType('phone')
        break
      case 'Usuario ePayco.me':
        setInputType('domain')
        break
      default:
        break
    }
  }

  const handleChange = (value: string, isValid: boolean) => {
    setFormValid(isValid)
    setInputValue(value)
  }

  const refreshAsyncToken = async () => {
    const token = await captcha.getToken()
    dispatch(setToken(token))
    setIsFetching(false)
    setErrorMessage(() => {
      switch (inputType) {
        case 'email':
          return 'Ingrese un correo electrónico asociado a una cuenta ePayco.me'
        case 'phone':
          return 'Ingrese un teléfono celular asociado a una cuenta ePayco.me'
        case 'domain':
          return 'Ingrese un usuario ePayco.me válido'
        default:
          return ''
      }
    })
  }

  const handleOnSubmit = () => {
    const _dataService = new DataService()
    _dataService
      .post('/miepayco/search ', {
        parameter: inputType,
        value: inputValue,
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(setUserInfo(response.data.data))
          window.location.href = response.data.data.subdomain
        } else {
          refreshAsyncToken()
        }
      })
      .catch(() => {
        setErrorMessage('Ocurrió un error, intente luego')
        refreshAsyncToken()
      })
  }

  const handleTokenCaptcha = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsFetching(true)
    const valid = await dataService.validateRecaptcha(tokenCaptcha)
    valid
      ? handleOnSubmit()
      : setErrorMessage('Ocurrio un error con el captcha, por favor recarga la pagina')
  }
  return (
    <SubmitUserFormContainer onSubmit={(e) => handleTokenCaptcha(e)}>
      <SubmitUserFormHeader>
        <h4>Realice pagos a una cuenta ePayco</h4>
        <p>
          {`Seleccione la forma de identificar el usuario al cual 
                    desea realizarle un pago. Puede identificarlo indicando su 
                    Correo electrónico, Teléfono o Usuario ePayco.me`}
        </p>
      </SubmitUserFormHeader>
      <InputContainer>
        <Selector
          items={selectData}
          renderOption={(item) => {
            return item.label
          }}
          onSelect={handleOnSelect}
          disabled={isFetching}
        />
        <SearchUserInput
          type={inputType}
          url={config.epaycoUrl}
          disabled={inputDisabled || isFetching}
          onChange={handleChange}
          errorMessage={errorMessage}
        />
      </InputContainer>

      <Button type="submit" disabled={inputDisabled || !formValid} loading={isFetching}>
        Continuar
      </Button>
      <PoweredByEpaycoLogo />
    </SubmitUserFormContainer>
  )
}

export default SubmitUserForm
