import React from 'react'
import { HeaderContainer, HeaderLogo, HeaderBody } from './styles'
import { EpaycoLogo } from '../../assets/svg'
import MyEpacycoInfo from '../MyEpaycoInfo'
import SubmitUserForm from '../SubmitUserForm'
import { useHeaderView } from '../../hooks'
import InfoCard from '../InfoCard'
import Page from '../../theme/page'

const Header = () => {
  const { viewState, userState } = useHeaderView()
  const background = userState.backgroundImage

  return (
    <HeaderContainer background={viewState.loading ? '' : background} color={'white'}>
      <HeaderLogo>
        <EpaycoLogo />
      </HeaderLogo>
      <HeaderBody justify={viewState.view !== 'home'}>
        {viewState.view === 'home' ? (
          <>
            <Page title={'Recibir pagos o realizar cobros con ePayco.me | ePayco.com'} />
            <MyEpacycoInfo background={background} />
            <SubmitUserForm />
          </>
        ) : (
          <>
            <Page title={'Recibir pagos o realizar cobros con ePayco.me | ePayco.com'} />
            <InfoCard view={viewState} userInfo={userState} />
          </>
        )}
      </HeaderBody>
    </HeaderContainer>
  )
}

export default Header
