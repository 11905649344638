import React, { useState } from 'react'
import { Button } from '../../../theme/common'
import { FilePdf, PoweredByEpaycoLogo } from '../../../assets/svg'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config/enviroment'
import { DataService } from '../../../services/dataService'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers'
import { UserReducerType } from '../../../types'
import {
  ContainerDataTransaction,
  TitleDataTransaction,
  DivRightDataTransaction,
  DivLeftDataTransaction,
  DivTableDataTransaction,
  DivContainerTable,
  DivFooterDataTransaction,
  TransactionDataContainer,
  Space,
  LinkEpayco,
} from './styles'

const TransactionData = ({ data }: any) => {
  const userState: UserReducerType = useSelector((state: RootState) => state.user)
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const getReport = () => {
    setLoading(true)
    const dataService = new DataService()
    dataService
      .post('/miepayco/payment/receipt', {
        id: data.ref_payco,
        url: userState.subdomain,
      })
      .then((response) => {
        if (response.status) {
          response.data.data.file && openReportPDF(response.data.data.file)
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const openReportPDF = (base64: string) => {
    const pdfWindow = window.open()
    pdfWindow?.document.write(
      '<title>Comprobante ePayco</title>' +
        "<iframe style='position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none;' src='" +
        base64 +
        "'></iframe>"
    )
  }

  return (
    <TransactionDataContainer>
      <ContainerDataTransaction>
        <TitleDataTransaction>Detalle de la transacción</TitleDataTransaction>
        <DivContainerTable>
          <DivTableDataTransaction>
            <DivLeftDataTransaction>Referencia ePayco</DivLeftDataTransaction>
            <DivLeftDataTransaction>Fecha y hora</DivLeftDataTransaction>
            <DivLeftDataTransaction>Medio de pago</DivLeftDataTransaction>
            <DivLeftDataTransaction>Autorización</DivLeftDataTransaction>
            <DivLeftDataTransaction>Nro Recibo</DivLeftDataTransaction>
          </DivTableDataTransaction>

          <DivTableDataTransaction>
            <DivRightDataTransaction>{data.ref_payco}</DivRightDataTransaction>
            <DivRightDataTransaction>{data.transaction_date}</DivRightDataTransaction>
            <DivRightDataTransaction>{data.franchise}</DivRightDataTransaction>
            <DivRightDataTransaction>{data.approval_code}</DivRightDataTransaction>
            <DivRightDataTransaction>{data.id_invoice}</DivRightDataTransaction>
          </DivTableDataTransaction>
        </DivContainerTable>
      </ContainerDataTransaction>

      <DivFooterDataTransaction>
        {data.status === 'ACEPTADA' || data.status === 'PENDIENTE' ? (
          <>
            {data.status === 'ACEPTADA' && (
              <>
                <Button type="button" typeBottom="secondary" onClick={getReport} loading={loading}>
                  <FilePdf style={{ marginRight: '0.4rem', height: '1rem' }} />
                  Ver comprobante
                </Button>
                <Space />
              </>
            )}
            <Button
              type="button"
              onClick={() => config.epaycoUrl && window.location.replace(config.epaycoUrl)}
            >
              Realizar otro pago
            </Button>
          </>
        ) : (
          <Button type="button" onClick={() => history.push('/')}>
            Reintentar transacción
          </Button>
        )}
        <Space />
        <LinkEpayco href={config.epaycoMainUrl}>Conoce más de ePayco</LinkEpayco>
        <PoweredByEpaycoLogo style={{ marginTop: '.8rem' }} />
      </DivFooterDataTransaction>
    </TransactionDataContainer>
  )
}

export default TransactionData
