import styled from 'styled-components'

export const Form = styled.form`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  width: 20rem;
  height: 29.5rem;
  padding: 0.75rem 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 90%;
    min-height: 30rem;
    -webkit-box-shadow: 0px 13px 18px 7px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 13px 15px 2px rgba(0, 0, 0, 0.1);
  }
`
