import React from 'react'
import { MyEpacycoInfoContainer, MyEpaycoTitle, LogoEpaycoMe } from './styles'
import { THeaderContainer } from '../../types/HeaderProps'
import { config } from '../../config/enviroment'

const MyEpaycoInfo = ({ background }: THeaderContainer) => {
  return (
    <MyEpacycoInfoContainer background={background}>
      <MyEpaycoTitle>
        <LogoEpaycoMe
          src={config.amazonUrl + '/my-epayco/img/my_epayco_me.png'}
          alt="Logo ePayco.me"
        />
      </MyEpaycoTitle>
      <p>{`Una solución innovadora que llega a usted 
            para permitirle realizar pagos a través de la identificación 
            de la cuenta ePayco y solicitar cobros solamente 
            compartiendo un link personalizado. Realice estas 
            transacciones al instante, de forma segura y desde 
            cualquier dispositivo.`}</p>
    </MyEpacycoInfoContainer>
  )
}

export default MyEpaycoInfo
