import styled from 'styled-components'

export const UserDataContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.segeoUI};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 60%;
  width: 70%;
  span {
    text-align: center;
    font-size: 0.8rem;
    position: relative;
    color: red;
    bottom: 0.8rem;
    margin-left: 2px;
  }
`

export const UserName = styled.h1`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: ${(props) => props.theme.colors.grey1};
`

export const UserInfoText = styled.div`
  color: ${(props) => props.theme.colors.grey4};
  margin: 0.2rem 0;
`
