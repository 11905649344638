import Home from '../views/Home'
import { RouteConfig } from 'react-router-config'
import NotFound from '../views/NotFound'
import Embedded from '../components/Embedded'

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: Home,
  },

  {
    path: '/transaction',
    exact: true,
    component: Home,
  },
  {
    path: '/embed',
    exact: false,
    component: Embedded,
    routes: [
      {
        path: '/transaction',
        exact: true,
        component: Embedded,
      },
    ],
  },
  {
    path: '*',
    exact: true,
    component: NotFound,
  },
]

export default routes
