import * as React from 'react'

export const ArrowShape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={1166}
      height={834}
      viewBox="0 0 1166 834"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1085.26 5.057c6.7-7.333 18.47-6.582 24.19 1.545l53.68 76.32c7.51 10.675-.67 25.295-13.7 24.463l-31.02-1.982c-6.52 32.774-14.15 65.371-23.16 97.664-11.63 41.671-25.58 82.823-42.43 122.906-16.85 40.069-36.63 79.07-59.675 116.14-23.034 37.071-49.356 72.21-78.749 104.542-29.406 32.305-61.746 61.967-96.746 87.876-34.918 26.033-72.113 48.738-110.677 68.307-2.385 1.287-4.825 2.451-7.266 3.601l-7.307 3.506a1271.46 1271.46 0 00-5.658 2.708c-2.984 1.434-5.969 2.869-8.969 4.263l-14.82 6.504a655.37 655.37 0 00-3.517 1.544l-.375.165c-3.637 1.606-7.274 3.212-10.983 4.645l-14.984 6.026c-.996.392-1.99.794-2.981 1.196-1.504.61-3.006 1.22-4.519 1.789l-7.567 2.78-15.136 5.574c-2.508.958-5.059 1.807-7.608 2.656l-7.637 2.561c-40.703 13.736-82.091 24.91-123.794 34.126-83.436 18.433-167.969 29.087-252.473 36.057C139.941 828.372 68.447 831.437-3 833.197v-16.973c70.92-3.962 141.711-9.21 212.104-17.17 83.503-9.504 166.554-22.637 247.48-43.192 40.443-10.271 80.324-22.404 119.189-36.879l7.295-2.697c2.426-.89 4.868-1.794 7.252-2.78l14.409-5.82 7.197-2.904c1.541-.633 3.06-1.306 4.578-1.978l1.592-.703.945-.413 14.203-6.231c3.516-1.483 6.942-3.13 10.365-4.776a649.38 649.38 0 013.674-1.756l13.969-6.656c.922-.463 1.842-.93 2.76-1.4 2.033-1.04 4.058-2.093 6.084-3.145l3.029-1.573 1.865-.962 6.854-3.547c1.263-.65 2.527-1.299 3.779-1.968a99.905 99.905 0 003.022-1.674c36.109-19.747 70.507-42.302 102.503-67.732 32.081-25.32 61.336-53.942 87.768-84.849 26.42-30.949 49.875-64.363 70.275-99.599 20.414-35.235 37.797-72.278 52.449-110.444 25.18-65.562 42.4-134.232 54.39-203.957l-23.25-1.485c-13.03-.831-19.29-16.375-10.49-26.008l62.97-68.869z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={-549}
          y1={1413.28}
          x2={1223.49}
          y2={-287.515}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#62548F" />
          <stop offset={1} stopColor="#62548F" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const MyEpaycoProfileSectionBackgroundShape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={1440}
      height={870}
      viewBox="0 0 1440 870"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1440 724.5c-464.757 205-957.295 170.653-1440 30V477.548C677.998 587.5 1137.29 333.5 1440 0v724.5z"
        fill="#F9F9F9"
      />
    </svg>
  )
}

export const MyEpaycoShareSectionBackgroundShape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={1440}
      height={788}
      viewBox="0 0 1440 788"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v772.5c882.5 59 1157-61 1440-294.5V0H517.969c-50.453 2.97-101.804 2.958-153.82 0H0z"
        fill="#62548F"
      />
    </svg>
  )
}

export const EpaycoProductsSectionBackgroundShape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={341}
      height={945}
      viewBox="0 0 341 945"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .066v926.618c116.816 24.077 230.451 23.707 341-.045V0C230.84 21.109 117.494 22.468 0 .066z"
        fill="#EFEEF4"
      />
    </svg>
  )
}

export const Spinner = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FFFFFF"
      {...props}
    >
      <g transform="translate(1 1)" strokeWidth={2} fill="none" fillRule="evenodd">
        <circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.5s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  )
}

export const NotFoundSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={360}
      height={360}
      viewBox="0 0 360 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M360 67.5v270c0 12.431-10.069 22.5-22.5 22.5h-315C10.069 360 0 349.931 0 337.5v-270h360z"
        fill="#F5F5F5"
      />
      <path d="M0 0h360v90H0V0z" fill="#1C0E49" />
      <path d="M45 33.75h22.5v22.5H45v-22.5zM90 33.75h22.5v22.5H90v-22.5z" fill="#080035" />
      <path
        d="M303.75 56.25h-157.5C140.04 56.25 135 51.21 135 45s5.04-11.25 11.25-11.25h157.5c6.21 0 11.25 5.04 11.25 11.25s-5.04 11.25-11.25 11.25z"
        fill="#62548F"
      />
      <path
        d="M142.896 242.618h-9.433V259.2h-22.852v-16.582h-34.16v-16.992l35.625-52.09h21.387v51.797h9.433v17.285zm-32.285-17.285v-9.961c0-1.875.059-4.395.176-7.559.156-3.203.274-4.883.352-5.039h-.645a61.822 61.822 0 01-4.512 8.438l-9.492 14.121h14.121zM210.807 216.485c0 15.156-2.539 26.27-7.618 33.34-5.078 7.031-12.851 10.547-23.32 10.547-10.234 0-17.949-3.672-23.144-11.016-5.196-7.383-7.793-18.34-7.793-32.871 0-15.234 2.539-26.406 7.617-33.516 5.078-7.109 12.851-10.664 23.32-10.664 10.195 0 17.891 3.711 23.086 11.133 5.234 7.383 7.852 18.399 7.852 33.047zm-38.848 0c0 9.688.605 16.348 1.816 19.981 1.211 3.632 3.243 5.449 6.094 5.449 2.891 0 4.922-1.875 6.094-5.625 1.211-3.75 1.816-10.352 1.816-19.805 0-9.492-.605-16.133-1.816-19.922-1.211-3.828-3.242-5.742-6.094-5.742-2.851 0-4.883 1.836-6.094 5.508-1.211 3.672-1.816 10.39-1.816 20.156zM283.521 242.618h-9.433V259.2h-22.852v-16.582h-34.16v-16.992l35.625-52.09h21.387v51.797h9.433v17.285zm-32.285-17.285v-9.961c0-1.875.059-4.395.176-7.559.156-3.203.274-4.883.352-5.039h-.645a61.822 61.822 0 01-4.512 8.438l-9.492 14.121h14.121z"
        fill="#080035"
      />
    </svg>
  )
}

export const Download = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.922 9.902c-.731 0-1.323.592-1.323 1.323v3.379H3.396v-3.379a1.323 1.323 0 00-2.646 0v4.702c0 .73.592 1.323 1.323 1.323h13.849c.73 0 1.323-.592 1.323-1.323v-4.702c0-.73-.593-1.323-1.323-1.323z" />
      <path d="M11.796 5.526L10.32 7.003v-4.93a1.323 1.323 0 10-2.646 0v4.93L6.196 5.526a1.319 1.319 0 00-1.871 0 1.323 1.323 0 000 1.871l3.736 3.736a1.323 1.323 0 001.87 0l3.736-3.736a1.323 1.323 0 10-1.87-1.871z" />
    </svg>
  )
}

export const FilePdf = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
      ></path>
    </svg>
  )
}
