import { SET_USER_INFO } from '../actions/'
import { config } from '../../config/enviroment'
import { UserReducerType } from '../../types'

const initialState: UserReducerType = {
  clientId: 0,
  maxValue: '0',
  minValue: '0',
  parameter: 'domain',
  success: false,
  value: '',
  profileImage: '',
  backgroundImage: `${config.amazonUrl}/my-epayco/img/my_epayco_banner.png`,
  email: '',
  idicativoPais: 57,
  name: '',
  businessName: '',
  phone: '',
  subdomain: '',
  publicKey: '',
}

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        ...action.payload,
        profileImage: action.payload.profileImage
          ? `${config.amazonUrl}/${action.payload.profileImage}`
          : '',
        backgroundImage: `${config.amazonUrl}/${
          action.payload.backgroundImage
            ? action.payload.backgroundImage
            : 'dashboard/v2/MiEpayco/textura.svg'
        }`,
      }
    }
    default: {
      return state
    }
  }
}

export default userReducer
