import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { UserReducerType } from '../../types'
import { UserContainer } from './styles'
import UserAvatar from './UserAvatar'
import UserData from './UserData'
import { PoweredByEpaycoLogo } from '../../assets/svg'
import { SkeletonContainer } from '../../theme/common'
import Skeleton from 'react-loading-skeleton'

const UserInfo = () => {
  const userState: UserReducerType = useSelector((state: RootState) => state.user)
  return (
    <UserContainer>
      {userState.value === '' ? (
        <LoadingSkeleton />
      ) : (
        <>
          <UserAvatar />
          <UserData />
          <PoweredByEpaycoLogo style={{ marginBottom: '.8rem' }} />
        </>
      )}
    </UserContainer>
  )
}

const LoadingSkeleton = () => {
  return (
    <SkeletonContainer>
      <Skeleton circle={true} height={'8rem'} width={'8rem'} />
      <Skeleton count={5} width={'100%'} />
      <Skeleton height={'2.5rem'} width={'100%'} />
      <Skeleton height={'2.5rem'} width={'100%'} />
    </SkeletonContainer>
  )
}

export default UserInfo
