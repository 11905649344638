import React from 'react'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import {
  ArrowShape,
  EpaycoDispersiones,
  EpaycoPagos,
  EpaycoRecaudo,
  EpaycoSuscripciones,
} from '../../assets/svg'
import { config } from '../../config/enviroment'
import {
  BackgroundShapeContainer,
  HowToRequestMoneyContainer,
  LandingInfoSection,
  LandingInfoImage,
  MainContainerLanding,
  MainTitle,
  NewLine,
  RowContainer,
  OrderedList,
  LinkEpayco,
  EpaycoProductsContainer,
  EpaycoProductsLogoContainer,
  EpaycoRatesContainer,
  EpaycoProfileContainer,
  ShowOnlyMobile,
  ShowOnlyDesktop,
  HowToSendMoneyContainer,
} from './styles'

const LandingInformation = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <MainContainerLanding>
      <MainTitle>
        Ahora con <b>ePayco.me</b> realice pagos y solicite cobros a través de su cuenta ePayco de
        forma ágil y segura.
      </MainTitle>
      <RowContainer>
        <LandingInfoSection alingMobile="left">
          <h3>{`Panel administrativo`}</h3>
          <p>
            Desde el <b>panel administrativo</b>, controle todas las transacciones realizadas a
            través de la solución <b>ePayco.me</b>. Visualice datos como:{` `}
            <NewLine />
            <NewLine />
            Fecha de la transacción
            <NewLine contentOnMobile=", " showOnMobileAs="inline" />
            Referencia de pago
            <NewLine contentOnMobile=", " showOnMobileAs="inline" />
            Medio de pago
            <NewLine contentOnMobile=", " showOnMobileAs="inline" />
            Valor y estado de cada transacción
            <NewLine contentOnMobile=". " showOnMobileAs="inline" />
            <NewLine />
            <NewLine />
            Además, en este apartado confirme los datos con los que lo identifican las personas que
            le realizan pagos a su cuenta y monitoree el saldo disponible.
          </p>
        </LandingInfoSection>
        <LandingInfoImage src={themeContext.images.myEpaycoAdminPanel} />
      </RowContainer>
      <BackgroundShapeContainer>
        <RowContainer displayDirecctionMobile="column-reverse">
          <LandingInfoImage src={themeContext.images.myEpaycoSecurity} />
          <LandingInfoSection alingMobile="left">
            <h3>{`Seguridad`}</h3>
            <p>
              Realice pagos y cobros con total confianza ya que ePayco cuenta con la{' '}
              <b>certificación PCI DSS Complice Nivel 1</b>, que es una de las más importantes
              certificaciones en seguridad, la cual certifica en estándares de alta calidad y
              seguridad a las compañías que procesan y almacenan datos de transacciones, con el fin
              de <b>prevenir la fuga de información</b> sensible en la adquisición de bienes y
              servicios online.
            </p>
          </LandingInfoSection>
        </RowContainer>
        <EpaycoProfileContainer>
          <RowContainer>
            <LandingInfoSection alingMobile="center">
              <h3>{`Personalice su cuenta`}</h3>
              <p>
                En el momento de activación de su cuenta tiene la posibilidad de agregar una imagen
                de perfil, imagen de fondo, asociar un correo y teléfono de contacto. Además, defina
                su propio subdominio o identificador ePayco.me. Edite la información e imágenes en
                el momento que lo requiera. Ofrecemos una experiencia totalmente personalizable.
              </p>
            </LandingInfoSection>
          </RowContainer>
          <RowContainer>
            <LandingInfoImage src={themeContext.images.myEpaycoProfile} />
          </RowContainer>
        </EpaycoProfileContainer>
        <ArrowShape />
      </BackgroundShapeContainer>
      <HowToRequestMoneyContainer>
        <LandingInfoSection alingMobile="right">
          <h3>{`¿Cómo realizar cobros?`}</h3>
          <p>
            Con <b>ePayco.me</b> realice cobros al instante compartiendo su link personalizado o
            Identificador <b>ePayco.me</b> , a través de correo electrónico, mensajes de texto o la
            red social de su preferencia.
          </p>
        </LandingInfoSection>
        <LandingInfoImage src={themeContext.images.myEpaycoShare} />
      </HowToRequestMoneyContainer>
      <HowToSendMoneyContainer displayDirecctionMobile="column-reverse">
        <LandingInfoImage
          src={themeContext.images.myEpaycoHowToSendMoney}
          style={{ minWidth: '60%' }}
        />
        <LandingInfoSection alingMobile="left">
          <h3>{`¿Cómo hacer un pago?`}</h3>
          <div>
            Realice un pago a cuentas ePayco.me fácil en tres simples pasos:
            <OrderedList>
              <li>
                <b>Identificar la cuenta ePayco.me</b>
                {` `}
                <br></br>
                Seleccione el identificador de la cuenta de la persona a la que le realizara el pago
                a través de diferentes métodos como: Correo electrónico, Número de celular, Id
                ePayco.me.
              </li>
              <li>
                <b>Defina el valor a pagar</b>
                {` `}
                <br></br>
                Ingrese el valor a pagar y utilice nuestro Checkout para definir el medio de pago
                con el que realizará la transacción.
              </li>
              <li>
                <b>Obtenga el comprobante</b>
                {` `}
                <br></br>
                Al finalizar la transacción reciba un comprobante que podrá descargar, imprimir o
                enviar a su correo.
              </li>
            </OrderedList>
          </div>
        </LandingInfoSection>
      </HowToSendMoneyContainer>
      <EpaycoRatesContainer>
        <LandingInfoSection alingMobile="center">
          <h3>{`Sin costos ocultos de habilitación`}</h3>
          <p>
            <b>ePayco</b> solamente le cobrará por transacciones aceptadas. Puede habilitar su
            cuenta <b>ePayco.me</b> en cualquier momento desde el panel administrativo y no tendrá
            que hacer pagos adicionales de acceso, habilitación o mensualidades por el uso de la
            solución.
            <br></br>
          </p>
          <LinkEpayco href={config.epaycoRatesUrl}>Conozca más de las tarifas ePayco</LinkEpayco>
        </LandingInfoSection>
      </EpaycoRatesContainer>
      <RowContainer>
        <LandingInfoSection alingMobile="left">
          <h3>{`Soporte 24/7`}</h3>
          <ShowOnlyMobile>
            <p>
              Soporte todo el día todos los días. Contamos con un completo Centro de ayuda y soporte
              el cual le permite acceder a una base de conocimiento, crear tickets de ayuda por
              medio del panel administrativo o página web, agendar reuniones de soporte a
              desarrolladores o contactar a un asesor por medio del chat en vivo de la página web o
              a través de nuestros canales telefónicos.
            </p>
          </ShowOnlyMobile>
          <ShowOnlyDesktop>
            <p>
              Soporte todo el día todos los días. Contamos con un completo Centro de ayuda y soporte
              el cual le permite:
            </p>
            <ul>
              <li>Acceder a una base de conocimiento.</li>
              <li>Crear tickets de ayuda por medio del panel administrativo o página web.</li>
              <li>Agendar reuniones de soporte a desarrolladores.</li>
              <li>Contactar a un asesor por medio del chat en vivo de la página web.</li>
              <li>A través de nuestros canales telefónicos.</li>
            </ul>
          </ShowOnlyDesktop>
          <LinkEpayco href={config.epaycoSupportUrl} style={{ alignSelf: 'center' }}>
            Hablar con soporte
          </LinkEpayco>
        </LandingInfoSection>
        <LandingInfoImage src={themeContext.images.myEpaycoCustomerService} />
      </RowContainer>
      <EpaycoProductsContainer>
        <MainTitle>Más productos y servicios ePayco</MainTitle>
        <RowContainer displayDirecctionMobile="column-reverse">
          <EpaycoProductsLogoContainer>
            <EpaycoPagos />
            <EpaycoDispersiones />
            <EpaycoRecaudo />
            <EpaycoSuscripciones />
          </EpaycoProductsLogoContainer>
          <LandingInfoSection>
            <p>
              En ePayco contamos con una extensa variedad de productos y servicios las cuales
              llevaran a cobrar, vender y recaudar de forma fácil, rápida y segura todo en un solo
              lugar.
            </p>
          </LandingInfoSection>
        </RowContainer>
        <LinkEpayco style={{ paddingBottom: '2rem' }} href={config.epaycoSectionContactUrl}>
          Conocer todos los productos
        </LinkEpayco>
      </EpaycoProductsContainer>
    </MainContainerLanding>
  )
}

export default LandingInformation
