import { config } from '../config/enviroment'

declare global {
  interface Window {
    ePayco: any
  }
}

/**
 *
 * @param name nombre del cliente
 * @param email email del cliente
 * @param external determina si el checkout se muestra como un iframe o incrustado dentro de la vista
 * @param publicKey llave publica del cliente
 * @param callback Se llama cuando se el iframe
 */

export function EpaycoCheckout(
  email: string,
  name: string,
  amount: number,
  redirectTo: string,
  external: boolean,
  publicKey: string,
  callback: () => void
) {
  const configCheckout = {
    key: publicKey,
    test: config.modeCheckout,
  }

  const handler = window.ePayco.checkout.configure(configCheckout)
  const numberInvoice = Math.random() * (999 - 100)
  const data = {
    //Parametros compra (obligatorio)
    name: `ePayco.me ${name}`,
    description: `Pago realizado desde ePayco.me`,
    invoice: parseInt(numberInvoice.toString()),
    currency: 'cop',
    amount: amount,
    tax_base: '0',
    tax: '0',
    country: 'co',
    lang: 'es',
    external: external.toString(),
    //Atributos opcionales
    extra1: 'MI_EPAYCO',
    response: `${config.homeUrl}${redirectTo}`,
    //Atributos cliente
    email_billing: '',
  }

  handler.open(data)
  // handler.onCloseModal = callback
  handler.onClosed(callback)
}
