import styled from 'styled-components'

export const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colors.darkBlue110};
  height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    width: 100px;
    position: absolute;
    left: 4rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 15vh;
    padding: 1rem;
    flex-direction: column;
    a {
      display: flex;
      justify-content: center;
      position: relative;
      left: 0;
    }
  }
`

export const FooterCopyRight = styled.div`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.lato};
  color: ${(props) => props.theme.colors.white};
  flex-grow: 1;
  text-align: center;
`
