export const SET_VIEW = 'SET_VIEW'
export const SET_LOADING = 'SET_LOADING'

export const setView = (view: string) => (dispatch: any) => {
  dispatch({
    type: SET_VIEW,
    payload: view,
  })
}
export const setLoading = (loading: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_LOADING,
    payload: loading,
  })
}
