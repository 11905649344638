import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers'
import { UserReducerType } from '../../../types'
import { Avatar, AvatarRounded, UserAvatarContainer, UserInitial } from './styles'

const UserAvatar = () => {
  const userState: UserReducerType = useSelector((state: RootState) => state.user)

  return (
    <UserAvatarContainer background={userState.backgroundImage}>
      <AvatarRounded>
        {userState.profileImage ? (
          <Avatar src={userState.profileImage} />
        ) : (
          <UserInitial>
            <span>{userState.name.substring(0, 1)}</span>
          </UserInitial>
        )}
      </AvatarRounded>
    </UserAvatarContainer>
  )
}

export default UserAvatar
