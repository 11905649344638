import React from 'react'
import { CardInfo } from './styles'
import TransactionInfo from '../TransactionInfo'
import UserInfo from '../UserInfo'
import { TInfoCard } from '../../types'
import { useQuery } from '../../hooks'

const InfoCard = ({ view, userInfo }: TInfoCard) => {
  const query = useQuery()

  const onSubmit = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault()
  }

  return (
    <CardInfo onSubmit={onSubmit}>
      {query.has('ref_payco') ? <TransactionInfo /> : <UserInfo />}
    </CardInfo>
  )
}

export default InfoCard
