import React from 'react'
import NumberFormat from 'react-number-format'
import { Input } from '../../theme/common'
import { MaskedInputProps } from '../../types'
import { MaskedInputContainer } from './styles'

const MaskedInput = (props: MaskedInputProps) => (
  <MaskedInputContainer>
    <NumberFormat
      customInput={Input}
      type={'text'}
      thousandSeparator={true}
      decimalScale={2}
      allowNegative={false}
      prefix={'$'}
      placeholder={'$0.00'}
      {...props}
    />
  </MaskedInputContainer>
)

export default MaskedInput
