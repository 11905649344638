import styled, { keyframes, css } from 'styled-components'
import { Input } from '../../theme/common'

const showErrorAnimation = keyframes`
    from {
        transform: translate(0, 100%);
        visibility: hidden;
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        visibility: visible;
        opacity: 1;
    }
`

export const InputFormContainer = styled.div`
  height: 2.5rem;
  margin-bottom: 1.5rem;
  max-width: ${(props) => (props.style?.maxWidth ? '100%' : '68%')};
  span {
    font-size: 0.6rem;
    position: relative;
    color: red;
    bottom: ${(props) => (props.style?.maxWidth ? '0.8rem' : '0.35rem')};
    margin-left: 2px;
    ${(props) =>
      props.children !== ''
        ? css`
            animation: ${showErrorAnimation} 0.75s forwards 1;
          `
        : null}
  }
`
export const SearchUserInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 1px ${(props) => props.theme.colors.grey30};
  border-radius: 4px;
  margin: 0.5rem 0;
  height: auto;

  ${Input} {
    border: 0;
  }
  * {
    margin: 0px;
  }
`

export const SearchUserUrlContainer = styled(SearchUserInputContainer)`
  border: solid 1px ${(props) => props.theme.colors.grey30};
  border-radius: 4px;
  padding: 0 0.4rem;

  // ${Input} {
  //   padding-top: 0.4rem;
  // }

  #http {
    font-family: ${(props) => props.theme.fonts.segeoUI};
    color: ${(props) => props.theme.colors.grey60};
  }

  #domain {
    font-family: ${(props) => props.theme.fonts.segeoUI};
    color: ${(props) => props.theme.colors.grey60};
  }
`

export const SearchUserPhoneContainer = styled(SearchUserInputContainer)`
  border: 0px;
  ${Input} {
    width: 100%;
    /* margin-left: 0.75rem; */
    border: solid 1px ${(props) => props.theme.colors.grey30};
  }
  * {
    margin: 0px;
    flex: 1;
  }
`

export const PhonePrefixContainer = styled.div`
  max-width: 30%;
`

export const FlagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0;
`
export const Flag = styled.img`
  max-width: 15px;
  margin: 0rem 0.2rem 0rem -0.3rem;
`
export const FlagLabel = styled.span`
  font-size: 10px;
  width: 60%;
`
