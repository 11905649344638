import { ThemeProvider } from 'styled-components'
import { useEffect } from 'react'
import './App.css'
import { theme } from './theme/theme'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from '../src/routes/RoutesComponent'
import Recaptcha from 're-capt-v3'
import { config } from './config/enviroment'
import { useDispatch } from 'react-redux'
import { setToken } from './redux/actions/catchAction'

function App() {
  const captcha = new Recaptcha(config.captchaKey, 'login')
  const dispatch = useDispatch()
  let token = ''
  useEffect(() => {
    verify()
  }, [])

  const asyncToken = async () => {
    token = await captcha.getToken()
    dispatch(setToken(token))
  }

  const verify = () => {
    if (document.readyState !== 'complete') {
      setTimeout(() => {
        verify()
      }, 500)
    } else {
      asyncToken()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  )
}

export default App
