import styled from 'styled-components'
import { Form } from '../../theme/common/Form'

export const CardInfo = styled(Form)`
  padding: 0;
  overflow: hidden;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    border-radius: 0;
    z-index: 2;
  }
`
