import styled from 'styled-components'
import { CardContainer } from '../../theme/common/CardContainer'

export const TransactionContainer = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  height: 100%;
`
