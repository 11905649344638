import React from 'react'
import { EpaycoLogo } from '../../assets/svg'
import { FooterContainer, FooterCopyRight } from './styles'

const Footer = () => {
  const currentDate = new Date()
  return (
    <FooterContainer>
      <EpaycoLogo />
      <FooterCopyRight>
        {`© Copyright 2011 - ${currentDate.getFullYear()} ePayco.co . Todos los derechos reservados`}
      </FooterCopyRight>
    </FooterContainer>
  )
}

export default Footer
