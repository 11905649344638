import { UserReducerType } from '../../types'

export const SET_USER_INFO = 'SET_USER_INFO'

export const setUserInfo = (data: UserReducerType) => (dispatch: any) => {
  dispatch({
    type: SET_USER_INFO,
    payload: { ...data },
  })
}
