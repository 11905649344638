import styled from 'styled-components'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  MyEpaycoProfileSectionBackgroundShape,
  MyEpaycoShareSectionBackgroundShape,
  EpaycoProductsSectionBackgroundShape,
} from '../../assets/svg'
import { LandingInfoProps, NewLineProps, RowContainerProps } from '../../types'

export const MainContainerLanding = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: -4rem;
  > * {
    > div,
    section {
      padding: 0rem 4rem;
      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 1rem;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem 0rem 0rem 0rem;
  }
  font-family: ${(props) => props.theme.fonts.openSans};
  color: ${(props) => props.theme.colors.darkBlue110};
`

export const MainTitle = styled.h1`
  color: ${(props) => props.theme.colors.darkBlue110};
  font-family: ${(props) => props.theme.fonts.kanit};
  font-size: 40px;
  text-align: center;
  line-height: 50px;
  width: 70%;
  font-weight: 100;
  align-self: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 28px;
    line-height: 30px;
    width: 90%;
  }
`

export const LandingInfoSection = styled.div<LandingInfoProps>`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  h3 {
    font-family: ${(props) => props.theme.fonts.kanit};
    color: ${(props) => props.theme.colors.darkBlue110};
    font-family: ${(props) => props.theme.fonts.kanit};
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 52px;
    padding: 0;
    margin: 0;
  }

  p {
    font-family: ${(props) => props.theme.fonts.openSans};
    color: ${(props) => props.theme.colors.darkBlue110};
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    > * {
      text-align: ${(props) => (props.alingMobile ? props.alingMobile : 'left')};
    }
  }
`

export const RowContainer = styled.section<RowContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  > :first-child {
    min-width: 35%;
    max-width: 35%;
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      min-width: 42%;
      max-width: 42%;
    }
  }

  > :only-child {
    min-width: 60%;
    max-width: 100%;
    text-align: center;

    * {
      text-align: center;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      min-width: 100%;
      max-width: 100%;
      flex-direction: ${(props) =>
        props.displayDirecctionMobile ? props.displayDirecctionMobile : 'column'};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    ${LandingInfoSection} {
      min-width: 100%;
      max-width: 100%;
    }
    flex-direction: ${(props) =>
      props.displayDirecctionMobile ? props.displayDirecctionMobile : 'column'};
  }
`
export const LandingInfoImageContainer = styled.div`
  border: 1px solid black;
  overflow: auto;
  max-height: 50%;
  display: flex;
  padding: 10px;
  width: 20vh;
  max-height: 50vh;
`
export const LandingInfoImage = styled.img`
  object-fit: scale-down;
  overflow: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`

export const BackgroundShapeContainer = styled.div`
  position: relative;
  width: 100%;
  svg {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60vh;
    left: -10rem;
    z-index: -1;
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      width: 100%;
      top: 0px;
      left: 0px;
    }
  }
`

export const OrderedList = styled.ul`
  li {
    position: relative;
    margin: 1rem 0;
    font-size: 16px;
    line-height: 22px;
    list-style: none;
    b {
      display: block;
    }
  }
  li::marker {
    content: '';
  }
  li:before {
    position: absolute;
    left: -2.2rem;
    content: counter(list-item);
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.darkBlue100};
    border: 1px solid ${(props) => props.theme.colors.darkBlue100};
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 2px white, 0 0 0 3px ${(props) => props.theme.colors.darkBlue100};
    margin-right: 0.5rem;
    font-family: ${(props) => props.theme.fonts.openSans};
  }
`

export const LinkEpayco = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${(props) => props.theme.colors.orangeEpayco};
  font-weight: bold;
  font-size: 14px;
`

export const EpaycoProductsContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-position-y: top;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8, ${encodeURIComponent(
    renderToStaticMarkup(React.createElement(EpaycoProductsSectionBackgroundShape))
  )}');
  padding-top: 4rem;
  position: relative;
  top: -4rem;
  ${RowContainer} {
    justify-content: space-around;
    padding: 0 5rem;
    > :first-child {
      min-width: 50%;
      max-width: 50%;
    }
    p {
      padding: 2rem 3rem;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 1rem;
      p {
        padding: 0;
      }
    }
  }

  li::marker {
    content: '';
  }
  ul {
    padding: 0;
    li {
      padding: 0.75rem 0;
      text-align: left;
      list-style: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 2rem;
  }
`

export const EpaycoProductsLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  padding: 2rem;

  > :nth-child(even) {
    align-self: flex-end;
    margin: 1.5rem 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 1.5rem;
    min-width: 100% !important;
    padding: 0;
    flex-grow: 1;
  }
`

export const EpaycoRatesContainer = styled(RowContainer)`
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: ${(props) => props.theme.colors.white};
  }
  align-items: flex-end;
  height: 25rem;
  background-size: 100% 100%;
  background-image: url(${(props) => props.theme.images.myEpaycoRateBackground});
  > * {
    width: 60%;
    margin-bottom: 2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    background-image: none;
    background-color: ${(props) => props.theme.colors.darkBlue110};
  }
`

export const EpaycoProfileContainer = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 5rem !important;
  background-size: cover;
  background-position-y: bottom;
  background-image: url('data:image/svg+xml;utf8, ${encodeURIComponent(
    renderToStaticMarkup(React.createElement(MyEpaycoProfileSectionBackgroundShape))
  )}');
`

export const HowToSendMoneyContainer = styled(RowContainer)`
  margin-top: -5rem;
`

export const HowToRequestMoneyContainer = styled(RowContainer)`
  background-position-y: bottom;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8, ${encodeURIComponent(
    renderToStaticMarkup(React.createElement(MyEpaycoShareSectionBackgroundShape))
  )}');
  position: relative;
  top: -10rem;
  padding-top: 10rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: ${(props) => props.theme.colors.white};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${LandingInfoImage} {
      position: relative;
      top: 3.5rem;
    }

    ${LandingInfoSection} {
      position: relative;
      top: 2rem;
    }
  }
`

export const ShowOnlyMobile = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const ShowOnlyDesktop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    align-self: center;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const NewLine = styled.br<NewLineProps>`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => props.showOnMobileAs};
    content: '';
    &:after {
      content: ${(props) => `"${props.contentOnMobile ? props.contentOnMobile : ''}"`};
    }
  }
`
