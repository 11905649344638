import styled from 'styled-components'

export const TitleResponseTransaction = styled.div`
  color: ${(props) => props.color};
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
`

export const ContainerTitleTransaction = styled.div`
  height: 25%;
  width: 100%;
  background-color: ${(props) => props.color};
  position: relative;
`

export const TitleResponseInfo = styled.p`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #5c5b5c;
  display: inline-block;
  margin: 0.3rem;
`

export const Space = styled.div`
  margin-top: 15px;
`
export const TransactionInfo = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #d3d3d3;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: absolute;
  top: 25%;
  left: 7.5%;
  right: 7.5%;
  padding: 0.5rem;
`
