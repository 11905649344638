export const config = {
  headerIn: {
    dispositivo: null,
    canal: null,
    medio: null,
    aplicacion: null,
    tipoTransaccion: null,
    usuario: null,
    uuid: null,
    fechaHora: null,
    idioma: null,
    empresa: null,
    geolocalizacion: null,
    token: '',
  },
  api: process.env.REACT_APP_URL,
  path: 'testpath',
  landingIdentifier: 'miePayco',
  //homeUrl: 'https://foo.epayco.xyz',
  homeUrl: window.location.origin,
  transactionUrl: window.location.pathname + '/transaction',
  amazonUrl: process.env.REACT_APP_AMAZON_URL,
  epaycoUrl: process.env.REACT_APP_EPAYCO_URL,
  epaycoMainUrl: process.env.REACT_APP_EPAYCO_MAIN_URL,
  epaycoSectionContactUrl: process.env.REACT_APP_EPAYCO_MAIN_URL + '/#contact-section',
  epaycoRatesUrl: process.env.REACT_APP_EPAYCO_MAIN_URL + '/tarifas',
  epaycoSupportUrl: process.env.REACT_APP_EPAYCO_MAIN_URL + '/contacto/',
  // captchaKey: process.env.REACT_APP_CAPTCHA_KEY || '',
  captchaKey: '6LdUUO4aAAAAADuq2dscY2v6UyyYmrJyBjXF1Bn8',
  modeCheckout: process.env.REACT_APP_CHECKOUT_MODE !== 'prod',
  urlResultCheckout: process.env.REACT_APP_URL_RESULT_CHECKOUT,
  apifyPrivate: process.env.REACT_APP_APIFY_PRIVATE,
  environment: process.env.REACT_APP_CHECKOUT_MODE,
}
