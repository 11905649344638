import styled from 'styled-components'
import { AvatarProps, UserAvatarContainerProps } from '../../../types'

export const UserAvatarContainer = styled.div<UserAvatarContainerProps>`
  height: 25%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkBlue30};
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
`

export const AvatarRounded = styled.div`
  position: absolute;
  top: 30%;
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
`
export const Avatar = styled.img<AvatarProps>`
  object-fit: cover;
  width: inherit;
  height: inherit;
`
export const UserInitial = styled.div`
  background-color: rgb(28, 14, 73);
  color: rgb(255, 255, 255);
  text-align: center;
  height: inherit;
  width: inherit;

  span {
    line-height: 116px;
    font-size: 6rem;
  }
`
