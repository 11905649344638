import { combineReducers } from 'redux'
import captchaReducer from './captchaReducer'
import userReducer from './userReducer'
import viewReducer from './viewReducer'

const rootReducer = combineReducers({
  captcha: captchaReducer,
  user: userReducer,
  view: viewReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
