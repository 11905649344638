import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LandingInformation from '../../components/LandingInformation'
import { useHeaderView } from '../../hooks'

const Home = () => {
  const { viewState } = useHeaderView()
  return (
    <>
      <Header />
      {viewState.view === 'home' && <LandingInformation />}
      <Footer />
    </>
  )
}

export default Home
