import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/reducers/index'
import { setUserInfo } from '../redux/actions/userActions'
import { setView, setLoading } from '../redux/actions/viewActions'
import { config } from '../config/enviroment'
import { UserReducerType, ViewReducerType } from '../types'
import { DataService } from '../services/dataService'
import { useHistory } from 'react-router'

export const useHeaderView = () => {
  const viewState: ViewReducerType = useSelector((state: RootState) => state.view)
  const userState: UserReducerType = useSelector((state: RootState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch()

  const condition =
    config.environment === 'prod'
      ? !window.location.href.includes('www')
      : window.location.href.split('.').length > 2

  useEffect(() => {
    if ((condition || window.self !== window.top) && !viewState.loading) {
      const request = () => {
        const _dataService = new DataService()
        _dataService
          .post('/miepayco/search', {
            parameter: 'domain',
            value: config.homeUrl,
          })
          .then((response) => {
            if (response.data.success) {
              dispatch(setUserInfo(response.data.data))
              dispatch(setLoading(false))
            } else history.push('/notfound')
          })
          .catch((e) => {
            history.push('/notfound')
          })
      }
      if (window.self !== window.top) dispatch(setView('embedded'))
      else dispatch(setView('userView'))
      request()
    }
  }, [dispatch])

  return { viewState, userState }
}
